import { firebaseAdminAuth, firebaseAuth } from '../../firebase/index';
import Cookies from 'universal-cookie';
import firebase from 'firebase';
import appConstants from '../../appConstants';
import { saveDeviceTokens } from './readData.action';

// firebaseAuth.languageCode = "it";
firebaseAuth.languageCode = 'en';

const cookie = new Cookies();

export function createUserWithEmailAndPassword(email, password, cb) {
  console.log('createUserWithEmailAndPassword');
  return firebaseAuth.createUserWithEmailAndPassword(email, password);
}

export function signInWithEmailAndPassword(email, password, cb) {
  console.log('signInWithEmailAndPassword');
  firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(function (doc) {
      cb(null, doc);
    })
    .catch(function (error) {
      console.log('error:', error);
      cb(error.message, null);
    });
}

export const signInWithEmailAndPasswordForAdmin = async (
  email,
  password,
  cb
) => {
  try {
    await firebaseAdminAuth
      .signInWithEmailAndPassword(email, password)
      .then((doc) => {
        console.log('log of data', doc);
        cb(null, doc);
      })
      .catch((error) => {
        console.log('error:', error);
        cb(error.message, null);
      });
    // const result = await response.user
    // return result
  } catch (error) {
    console.log('error', error);
    // return error
  }
};

export const captchaVerifier = (id, handleSubmit) => {
  window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(id, {
    size: 'invisible',
    callback: (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      handleSubmit();
      console.log(response);
      console.log('recaptcha verified');
    },
    defaultCountry: 'IN',
  });
};

export const twoFactorAuthentication = async (phoneNumber, verifier) => {
  try {
    const response = await firebaseAdminAuth.signInWithPhoneNumber(
      phoneNumber,
      verifier
    );
    const result = await response.verificationId;
    window.confirmationResult = result;
    return result;
  } catch (error) {
    console.log('error', error);
    return false;
  }
};

export const loginWithCredentials = async (email, password, otp) => {
  try {
    const response = await firebaseAdminAuth.signInWithEmailAndPassword(
      email,
      password
    );
    const result = response.user;
    return result;
  } catch (error) {
    console.log('error', error);
    return false;
  }
};

export function getCurrentUser() {
  return firebaseAuth.currentUser;
}

export function emailAuthProvider(email, password) {
  console.log('emailAuthProvider');
  return firebase.auth.EmailAuthProvider.credential(email, password);
}

export async function onAuthStateChanged(cb) {
  await firebaseAuth.onAuthStateChanged(function (user) {
    cb(null, user);
  });
}

export async function signOut(isAdminAuth, history) {
  if (!isAdminAuth) {
    await firebaseAuth.signOut().then(
      async function () {
        await firebaseAuth.onAuthStateChanged(function (user) {
          if (!user) {
            localStorage.clear();
            let deviceToken = cookie.get('deviceToken', '', { path: '/' });
            let user = cookie.get('user', { path: '/' });
            console.log('logout user---', user);
            cookie.remove('user', { path: '/' });
            cookie.remove('clinic', { path: '/' });
            cookie.remove('deviceToken', { path: '/' });
            if (deviceToken && user && user.userId) {
              console.log('🚀 ~ deviceToken:', deviceToken);
              saveDeviceTokens(
                user.userId,
                deviceToken,
                'remove',
                (err, res) => {
                  console.log('err, res in savingfbnncd', err, res);
                  if (!err) {
                    cookie.set('deviceToken', '', { path: '/' });
                    history.push('/login');
                  }
                }
              );
            }
            window.location.reload();
          }
        });
      },
      function (error) {
        console.error('Sign Out Error', error);
      }
    );
  } else {
    console.log('run else -------------------');
    localStorage.clear();
    window.location.reload();
  }
}

export const sendPasswordResetMail = async (email, isAdminRoute) => {
  let link = isAdminRoute ? `${window.location.origin}/admin?`:`${window.location.origin}/login?`;
  return fetch(
    `${
      appConstants.firebaseFunctionsUrl
    }sendPasswordResetEmail?email=${encodeURIComponent(email)}&link=${link}&isAdmin=${isAdminRoute}`
  )
    .then((res) => res.json())
    .then(
      (result) => {
        if (result.success === false) {
          throw new Error('User Not exist');
        }
        return result.success;
      },
      (error) => {
        console.log('Error:', error);
      }
    )
    .catch((err) => {
      console.log('err', err);
      return {
        type: 'error',
        message: "User doesn't exist with this email.",
      };
    });
  // try {
  //   // console.log("hhhhhhhh", isAdminRoute);
  //   // const response = isAdminRoute
  //   //   ? await firebaseAdminAuth.sendPasswordResetEmail(email)
  //   //   : await firebaseAuth.sendPasswordResetEmail(email);
  //   let link = `${window.location.origin}/resetPassword?`;

  //   const respone = '';
  //   return { type: 'success', message: 'Email send successfully.', response };
  // } catch (error) {
  //   const { code, message } = error;
  // return {
  //   type: 'error',
  //   message:
  //     code === 'auth/user-not-found'
  //       ? "User doesn't exist with this email."
  //       : message || 'Somthing went wrong.',
  // };
  // }
};

export async function sendSetPasswordEmail(
  email,
  userId,
  firstName,
  clinicianName
) {
  let link = `${window.location.origin}/signup?ttoken=${userId}`;
  return fetch(
    `${
      appConstants.firebaseFunctionsUrl
    }sendSetPasswordEmail?email=${encodeURIComponent(
      email
    )}&link=${link}&firstName=${firstName}&clinicianName=${clinicianName}`
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result.success;
      },
      (error) => {
        console.log('Error:', error);
      }
    )
    .catch((err) => {
      console.log('err', err);
    });
}

export async function verifyPasswordResetCode(
  actionCode,
  password,
  isAdmin,
  cb
) {
  console.log('isAdmin', isAdmin);
  if (isAdmin) {
    await firebaseAdminAuth
      .verifyPasswordResetCode(actionCode)
      .then(async (email) => {
        await firebaseAdminAuth
          .confirmPasswordReset(actionCode, password)
          .then((resp) => {
            console.log('resp:', resp);
            cb(null, resp);
          })
          .catch((error) => {
            cb(error, null);
          });
      })
      .catch((error) => {
        cb(error, null);
      });
  } else {
    await firebaseAuth
      .verifyPasswordResetCode(actionCode)
      .then(async (email) => {
        await firebaseAuth
          .confirmPasswordReset(actionCode, password)
          .then((resp) => {
            console.log('resp:', resp);
            cb(null, resp);
          })
          .catch((error) => {
            cb(error, null);
          });
      })
      .catch((error) => {
        cb(error, null);
      });
  }
}

export async function verifyEmail(actionCode, cb) {
  await firebaseAuth
    .applyActionCode(actionCode)
    .then(async (resp) => {
      console.log('resp:', resp);
      cb(null, resp);
    })
    .catch((error) => {
      cb(error, null);
    });
}

export async function sendEmailVerification(email, name, clinicName, cb) {
  //console.log("Hello");
  let link = `${window.location.origin}/resetPassword`;
  // await firebaseAuth.currentUser
  //   .sendEmailVerification({
  //     url: link,
  //   })
  //   .then(() => {
  //     //console.log("Verification Email Has been Sent");
  //     cb(null, { success: true });
  //   })
  //   .catch((error) => {
  //     //console.log("Email Verification Failed", error);
  //     cb(error, null);
  //   });

  return fetch(
    `${
      appConstants.firebaseFunctionsUrl
    }sendEmailVerification?email=${encodeURIComponent(
      email
    )}&link=${link}&name=${name}&clinicName=${clinicName}&clinic=${true}`
  )
    .then((res) => res.json())
    .then(
      (result) => {
        console.log('result:', result);
        cb(null, result);
      },
      (error) => {
        console.log('Error:', error);
        cb(error, null);
      }
    );
}

function objectToQueryParams(obj) {
  return Object.keys(obj)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
    .join('&');
}

export const clinicVerificationMailResent = async (data) => {
  try {
    const response = await fetch(
      `${
        appConstants.firebaseFunctionsUrl
      }/sendEmailVerification?${objectToQueryParams(data)}`
    );

    const value = response.json();
    return value;
  } catch (error) {
    console.log('🚀 ~ clinicVerificationMail ~ error:', error);
    alert(error);
  }
};
export const patientVerificationMailResent = async (data) => {
  try {
    const response = await fetch(
      `${
        appConstants.firebaseFunctionsUrl
      }/sendPatientEmailVerification?${objectToQueryParams(data)}`
    );

    const value = response.json();
    return value;
  } catch (error) {
    console.log('🚀 ~ clinicVerificationMail ~ error:', error);
    alert(error);
  }
};
