import React, { useEffect, useState, lazy } from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import _ from 'lodash';
import { getData, onAuthStateChanged } from '../src/store/actions';
import './components/AdminScreen/admin.css';
import './styles/css/style.css';

import { signOut } from '../src/store/actions';

const Login = lazy(() => import('./components/Login'));
const Signup = lazy(() => import('./components/Signup'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const AdminScreen = lazy(() => import('./components/AdminScreen'));
const ClinicList = lazy(() => import('./components/ClinicList'));
const AddCarePlanTemplates = lazy(() =>
  import('./components/AddCarePlanTemplates')
);
const Clinicdetail = lazy(() => import('./components/Clinicdetail'));
const Careplan = lazy(() => import('./components/Careplan'));
const HealthNotes = lazy(() => import('./components/HealthNotes'));
const Addcareplan = lazy(() => import('./components/Addcareplan'));
const Documents = lazy(() => import('./components/Documents'));
const Progress = lazy(() => import('./components/Progress'));
const VideoChatWrapper = lazy(() => import('./components/VideoChatWrapper'));
const Subscriptionplan = lazy(() => import('./components/Subscriptionplan'));
const Profile = lazy(() => import('./components/Profile'));
const PaymentStatus = lazy(() => import('./components/Common/PaymentStatus'));
const ResetPassword = lazy(() => import('./components/ResetPassword'));
const Loader = lazy(() => import('./components/Loader'));
const Notification = lazy(() => import('./Notification'));
const Layout = lazy(() => import('./Layout'));

const cookie = new Cookies();

const initialState = {
  authenticated: null,
  isLoading: true,
  role: null,
  isLogged: false,
  user: {},
};

const token = localStorage.getItem('user');

const allRoutes = [
  {
    id: 'login',
    component: Login,
    path: '/login',
    isAuth: true,
    exact: true,
    default: true,
    redirect: '/dashboard',
  },
  {
    id: 'signup',
    component: Signup,
    path: '/signup',
    isAuth: true,
    exact: true,
    default: true,
    redirect: '/dashboard',
  },
  {
    id: 'reset_password',
    component: ResetPassword,
    path: '/resetPassword',
    isAuth: true,
    exact: true,
    default: true,
    redirect: '/dashboard',
  },
  {
    id: 'reset_password_admin',
    component: ResetPassword,
    path: '/admin/resetPassword',
    isAuth: true,
    exact: true,
    default: true,
    isAdminAuthRoute: true,
    redirect: '/admin/dashboard',
  },
  {
    id: 'admin_login',
    requiredSession: true,
    component: Login,
    path: '/admin',
    isAuth: true,
    default: true,
    isAdminAuthRoute: true,
    exact: true,
    redirect: '/admin/dashboard',
  },
  {
    id: 'user_profile',
    requiredSession: true,
    component: Profile,
    path: '/profile/:id',
    isAuth: false,
    exact: true,
    role: 'user',
    redirect: '/login',
  },
  {
    id: 'admin_profile',
    requiredSession: true,
    component: Profile,
    path: '/admin/profile',
    isAuth: false,
    exact: true,
    role: 'admin',
    redirect: '/admin',
  },
  {
    id: 'user_dashboard',
    requiredSession: true,
    component: Dashboard,
    path: '/dashboard',
    isAuth: false,
    exact: true,
    role: 'user',
    redirect: '/login',
  },
  {
    id: 'admin_dashboard',
    requiredSession: true,
    component: AdminScreen,
    path: '/admin/dashboard',
    isAuth: false,
    exact: true,
    role: 'admin',
    redirect: '/admin',
    isAdminAuthRoute: true,
  },
  {
    id: 'admin_patient_list',
    requiredSession: true,
    component: Dashboard,
    path: '/admin/patients',
    isAuth: false,
    exact: true,
    role: 'admin',
    redirect: '/admin',
    isAdminAuthRoute: true,
  },
  {
    id: 'admin_single_patient_careplan',
    requiredSession: true,
    component: Careplan,
    path: '/admin/careplan/:id',
    isAuth: false,
    exact: true,
    role: 'admin',
    redirect: '/admin',
    isAdminAuthRoute: true,
  },
  {
    id: 'admin_single_patient_progress',
    requiredSession: true,
    component: Progress,
    path: '/admin/progress/:id',
    isAuth: false,
    exact: true,
    role: 'admin',
    redirect: '/admin',
    isAdminAuthRoute: true,
  },
  {
    id: 'admin_single_patient_documents',
    requiredSession: true,
    component: Documents,
    path: '/admin/documents/:id',
    isAuth: false,
    exact: true,
    role: 'admin',
    redirect: '/admin',
    isAdminAuthRoute: true,
  },
  {
    id: 'admin_single_patient_health_notes',
    requiredSession: true,
    component: HealthNotes,
    path: '/admin/healthnotes/:id',
    isAuth: false,
    exact: true,
    role: 'admin',
    redirect: '/admin',
    isAdminAuthRoute: true,
  },
  {
    id: 'admin_clinic_details',
    requiredSession: true,
    component: Clinicdetail,
    path: '/admin/clinicdetail/:id',
    isAuth: false,
    exact: true,
    role: 'admin',
    redirect: '/admin',
    isAdminAuthRoute: true,
  },
  {
    id: 'admin_clinic_list',
    requiredSession: true,
    component: ClinicList,
    path: '/admin/clinics',
    isAuth: false,
    exact: true,
    role: 'admin',
    redirect: '/admin',
  },
  {
    id: 'add_care_plan_templates',
    requiredSession: true,
    component: AddCarePlanTemplates,
    path: '/admin/care-plan-templates/:id',
    isAuth: false,
    exact: true,
    role: 'admin',
    redirect: '/admin',
  },
  {
    id: 'new_add_care_plan_templates',
    requiredSession: true,
    component: AddCarePlanTemplates,
    path: '/care-plan-templates',
    isAuth: false,
    exact: true,
    role: 'admin',
    redirect: '/admin',
  },
  {
    id: 'admin_add_care_plan',
    requiredSession: true,
    component: Addcareplan,
    path: '/admin/addcareplan/:id',
    isAuth: false,
    exact: true,
    role: 'admin',
    redirect: '/admin',
    isAdminAuthRoute: true,
  },
  {
    id: 'clinic_details',
    requiredSession: true,
    component: Clinicdetail,
    path: '/clinicdetail',
    isAuth: false,
    exact: true,
    role: 'user',
    redirect: '/login',
  },
  {
    id: 'care_plan',
    requiredSession: true,
    component: Careplan,
    path: '/careplan/:id',
    exact: true,
    role: 'user',
    redirect: '/login',
  },
  {
    id: 'health_notes',
    requiredSession: true,
    component: HealthNotes,
    path: '/healthnotes/:id/(condition)?/:conditionId?',
    exact: true,
    role: 'user',
    redirect: '/login',
  },
  {
    id: 'add_care_plan',
    requiredSession: true,
    component: Addcareplan,
    path: '/addcareplan/:id',
    exact: true,
    role: 'user',
    redirect: '/login',
  },
  {
    id: 'telehealth',
    requiredSession: true,
    component: VideoChatWrapper,
    path: '/telehealth/:id',
    exact: true,
    role: 'user',
    redirect: '/login',
  },
  {
    id: 'payment_status',
    requiredSession: true,
    component: PaymentStatus,
    path: '/checkout/:status',
    exact: true,
    role: 'user',
    redirect: '/login',
  },
  {
    id: 'documents',
    requiredSession: true,
    component: Documents,
    path: '/documents/:id',
    exact: true,
    role: 'user',
    redirect: '/login',
  },
  {
    id: 'user_progress',
    requiredSession: true,
    component: Progress,
    path: '/progress/:id',
    exact: true,
    role: 'user',
    redirect: '/login',
  },
  {
    id: 'subscription_plan',
    requiredSession: true,
    component: Subscriptionplan,
    path: '/subscriptionplan',
    exact: true,
    role: 'user',
    redirect: '/login',
  },
];

const App = ({ adminData, ...props }) => {
  const [user, setUser] = useState(initialState);
  setTimeout(() => {
    if (!adminData) {
      signOut();
    } else {
      localStorage.clear();
      window.location.reload();
    }
  }, 16200000);

  const handleAdminUser = () => {
    const userData = JSON.parse(adminData);
    setUser({
      authenticated: !!userData?.user_id,
      isLoading: false,
      isAdmin: userData?.isAdmin,
      role: userData?.role,
      isLogged: !!userData?.user_id,
      user: userData,
    });
  };

  const handleUser = async () => {
    try {
      setUser({
        ...user,
        isLoading: true,
      });

      const authenticated = await new Promise((resolve, reject) => {
        onAuthStateChanged((err, authenticated) => {
          if (!err) {
            resolve(authenticated);
          } else {
            reject(err);
          }
        });
      });

      // console.log('authenticated', authenticated);

      if (authenticated.emailVerified) {
        if (
          !_.isEmpty(cookie.get('user', { path: '/' })) &&
          authenticated.emailVerified
        ) {
          // console.log('if runnner ----');
          // console.log(
          //   'user after login1 --- -',
          //   cookie.get('user', { path: '/' }) || {}
          // );

          setUser({
            user: cookie.get('user', { path: '/' }) || {},
            authenticated: authenticated?.uid,
            isLoading: false,
            role: 'user',
            isLogged: true,
            emailVerified: authenticated.emailVerified,
          });
        } else {
          await getData('users', authenticated?.uid, async (err, userRes) => {
            cookie.set('user', userRes || {}, { path: '/' });
            console.log('user after login2 --- -', userRes, authenticated);

            setUser({
              user: userRes ? userRes : {},
              authenticated: authenticated?.uid,
              isLoading: false,
              role: 'user',
              isLogged: true,
              emailVerified: authenticated.emailVerified,
            });
          });
        }
      } else {
        setUser({ ...initialState, isLoading: false });
        signOut();
      }
    } catch (error) {
      console.error('Error in handleUser:', error);
      setUser({ ...initialState, isLoading: false });
    }
  };

  // const handleUser = async () => {
  //   new Promise((resolve, reject) => {
  //     setUser({
  //       ...user,
  //       isLoading: true
  //     })
  //     onAuthStateChanged(async (err, authenticated) => {

  //       if (!err) {
  //         if (authenticated) {

  //           if (!_.isEmpty(cookie.get("user", { path: "/" }))) {
  //             setUser({
  //               user: cookie.get("user", { path: "/" }) || {},
  //               authenticated: authenticated?.uid,
  //               isLoading: false,
  //               role: "user",
  //               isLogged: true,
  //             })
  //           } else {
  //             await getData("users", authenticated?.uid, async (err, userRes) => {
  //               console.log("eww-----", userRes);
  //               cookie.set("user", userRes || {}, { path: "/" })
  //               setUser({
  //                 user: userRes ? userRes : {},
  //                 authenticated: authenticated?.uid,
  //                 isLoading: false,
  //                 role: "user",
  //                 isLogged: true,
  //               })
  //             })
  //           }

  //         } else {
  //           setUser({ ...initialState, isLoading: false });
  //         }
  //       }
  //     });
  //   });
  // };

  useEffect(() => {
    adminData ? handleAdminUser() : handleUser();
    // eslint-disable-next-line
  }, [adminData, token]);

  // const handleUser = async () => {
  //   new Promise((resolve, reject) => {
  //     onAuthStateChanged(async (err, authenticated) => {

  //       console.log("cookie", cookie.get("user", { path: "/" }) || {});
  //       if (!err) {
  //         authenticated && cookie.get("user", { path: "/" })
  //           ? setUser({
  //             user: cookie.get("user", { path: "/" }) || {},
  //             authenticated: authenticated?.uid,
  //             isLoading: false,
  //             role: "user",
  //             isLogged: true,
  //           })
  //           : setUser({ ...initialState, isLoading: false });

  //         resolve();
  //       } else {
  //         reject(err);
  //       }
  //     });
  //   });
  // };

  const roleWiseAccess = (access) => {
    if (!user.role) {
      return allRoutes.filter((val) => val.default);
    } else {
      const cloneData = allRoutes.filter((val) => val?.role === access);
      return cloneData.length
        ? cloneData
        : allRoutes.filter((val) => val.default);
    }
  };

  const handleIsLoggedIn = () => {
    // console.log('ddd', !_.isEmpty(cookie.get('user', { path: '/' })));
    return user.isAdmin
      ? user.role === 'admin'
      : !_.isEmpty(cookie.get('user', { path: '/' }));
  };
  return (
    <div className="app-layout" {...props}>
      <Notification />
      <BrowserRouter>
        {user.isLoading ? (
          <div className="custm-loader">
            <Loader />
          </div>
        ) : (
          <Switch>
            {roleWiseAccess(user.role).map(
              (
                {
                  requiredSession,
                  redirect,
                  isAdminAuthRoute,
                  id,
                  isAuth,
                  component: Component,
                  ...extraData
                },
                index
              ) => {
                return (
                  <Route
                    key={index}
                    {...extraData}
                    render={(props) => (
                      <Layout
                        user={user}
                        component={Component}
                        {...props}
                        {...{
                          requiredSession,
                          isAuth,
                          redirect,
                          isAdminAuthRoute,
                          id,
                          ...extraData,
                        }}
                      />
                    )}
                  />
                );
              }
            )}
            {handleIsLoggedIn() && user.role ? (
              <Route
                path="*"
                render={() => (
                  <Redirect
                    to={{
                      pathname:
                        user.role === 'admin'
                          ? '/admin/dashboard'
                          : '/dashboard',
                    }}
                  />
                )}
              />
            ) : (
              <Redirect to="/login" />
            )}
          </Switch>
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
