const eventsDbConfigUAT = {
  apiKey: 'AIzaSyDfy6H6tDWOncL0rzTlHVnWPcnj7FzaBWA',
  authDomain: 'nc-events-uat2.firebaseapp.com',
  databaseURL: 'https://nc-events-uat2-default-rtdb.firebaseio.com',
  projectId: 'nc-events-uat2',
  storageBucket: 'nc-events-uat2.appspot.com',
  messagingSenderId: '431087997489',
  appId: '1:431087997489:web:747aa1984a242a39bb7b10',
  measurementId: 'G-635Z432ZQ1',
};

const operationsDbConfigUAT = {
  apiKey: 'AIzaSyCuuStmxxOA6_gVEQF2q06CnNYxKP10lh4',
  authDomain: 'nc-operations-uat2.firebaseapp.com',
  projectId: 'nc-operations-uat2',
  storageBucket: 'nc-operations-uat2.appspot.com',
  messagingSenderId: '534698349448',
  appId: '1:534698349448:web:77cd76dad7b0aa5e24e03d',
  measurementId: 'G-JZ7B0MNG4K',
  databaseURL: 'https://nc-operations-uat2-default-rtdb.firebaseio.com/',
};

const masterDbConfigUAT = {
  apiKey: 'AIzaSyAQ7RI8JYbXDOdDrBunoW_HvL0E34q6rVk',
  authDomain: 'nc-master-uat2.firebaseapp.com',
  projectId: 'nc-master-uat2',
  storageBucket: 'nc-master-uat2.appspot.com',
  messagingSenderId: '1070790113598',
  appId: '1:1070790113598:web:7f6410c09ff4833fbf946f',
  measurementId: 'G-5C0T4RX97X',
  databaseURL: 'https://nc-master-uat2-default-rtdb.firebaseio.com',
};

const eventsDbConfigUATLF = {
  apiKey: 'AIzaSyCTUPPVrF96XNXhD8yULeAjvp2hPWvMq_Q',
  authDomain: 'nc-events-uatlf-2024-10-e135d.firebaseapp.com',
  projectId: 'nc-events-uatlf-2024-10-e135d',
  storageBucket: 'nc-events-uatlf-2024-10-e135d.appspot.com',
  messagingSenderId: '163717219850',
  appId: '1:163717219850:web:78d04cde5a522844f401f3',
  measurementId: 'G-W24XW719PC',
  databaseURL: 'https://nc-events-uatlf-2024-10-e135d-default-rtdb.firebaseio.com/',
};

const operationsDbConfigUATLF = {
  apiKey: 'AIzaSyDyVmeLhJBokUhxrXsNOFft8iGf5cABzDE',
  authDomain: 'nc-operations-uatlf-2024-8d988.firebaseapp.com',
  projectId: 'nc-operations-uatlf-2024-8d988',
  storageBucket: 'nc-operations-uatlf-2024-8d988.appspot.com',
  messagingSenderId: '125167634098',
  appId: '1:125167634098:web:4c64cf67465d9a6c6dabab',
  measurementId: 'G-3M9XB5PGBX',
  databaseURL: 'https://nc-operations-uatlf-2024-8d988-default-rtdb.firebaseio.com',
};

const masterDbConfigUATLF = {
  apiKey: 'AIzaSyDIdMwvogieiGaE6SM5cTDreyWGIvtA7Zs',
  authDomain: 'nc-master-uatlf-2024-10-e749e.firebaseapp.com',
  projectId: 'nc-master-uatlf-2024-10-e749e',
  storageBucket: 'nc-master-uatlf-2024-10-e749e.appspot.com',
  messagingSenderId: '903272731676',
  appId: '1:903272731676:web:c8e99a92ba22f28993bbd9',
  measurementId: 'G-P662E84BTB',
  databaseURL: 'https://nc-master-uatlf-2024-10-e749e-default-rtdb.firebaseio.com',
};

const eventsDbConfigPRODUCTION = {
  apiKey: 'AIzaSyCebj3A-wnv0dKL3yapLHaf64J6cSWeBGk',
  authDomain: 'nc-events-prod3.firebaseapp.com',
  projectId: 'nc-events-prod3',
  storageBucket: 'nc-events-prod3.appspot.com',
  messagingSenderId: '72967107324',
  appId: '1:72967107324:web:728afba5e22c2fbfc79d79',
  measurementId: 'G-HKGWRBDY55',
  databaseURL: 'https://nc-events-prod3-default-rtdb.firebaseio.com/',
};

const operationsDbConfigPRODUCTION = {
  apiKey: 'AIzaSyD4DKbptqEHmSrj24xVtM6-9co66jfIAc4',
  authDomain: 'nc-operations-prod3.firebaseapp.com',
  databaseURL: 'https://nc-operations-prod3-default-rtdb.firebaseio.com',
  projectId: 'nc-operations-prod3',
  storageBucket: 'nc-operations-prod3.appspot.com',
  messagingSenderId: '468069372952',
  appId: '1:468069372952:web:e96475693bdfd1238e5f6d',
  measurementId: 'G-XWMVY8Y3XW',
};

const masterDbConfigPRODUCTION = {
  apiKey: 'AIzaSyAuNk7R97IVLUMhQ3PSUQpxzgGNmX1epRs',
  authDomain: 'nc-master-prod3.firebaseapp.com',
  databaseURL: 'https://nc-master-prod3-default-rtdb.firebaseio.com',
  projectId: 'nc-master-prod3',
  storageBucket: 'nc-master-prod3.appspot.com',
  messagingSenderId: '619184794521',
  appId: '1:619184794521:web:8b4091275a46e496e2c108',
  measurementId: 'G-48CX7HRKJS',
};

// let vapidKeyUAT =
//   'BP-lyNT0oLUmdn3DT1xGU6KKv2gDDYx4CNOyBdtm27k51vLZEi_c-BxeSVH3FSwykNxlCoHC4IpykNy_eZEjOC8';
let vapidKeyUAT =
  'BJYStXs0F2WgcemTeGeyA-SwdrRCLOyhtm6koIsKgeNnZx_V7KPDlBBpE7TcsZ9QzeLLWJWzI8bISZv5PTbhPlE'; //UATLF
let vapidKeyPRODUCTION =
  'BKyruz4v5DyHFSlZaJMF8DFxb12wr8kEy2r8FMDbwx3i37nxfn0ckJaxG45-iAIEqquBGKDlJa96v7UNgK_Ca8k';

let eventsDbConfig = eventsDbConfigUATLF;
let operationsDbConfig = operationsDbConfigUATLF;
let masterDbConfig = masterDbConfigUATLF;
let vapidKey = vapidKeyUAT;

if (process.env.REACT_APP_HOST_ENV === 'production') {
  console.log('Production Runnig ------------------------------');
  eventsDbConfig = eventsDbConfigPRODUCTION;
  operationsDbConfig = operationsDbConfigPRODUCTION;
  masterDbConfig = masterDbConfigPRODUCTION;
  vapidKey = vapidKeyPRODUCTION;
}

// const fbConfig = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
const fbConfig = {
  eventsDbConfig,
  operationsDbConfig,
  masterDbConfig,
  vapidKey,
};
export default fbConfig;
