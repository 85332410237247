const roles = {
  ADMIN: 1,
  TEAMMEMBER: 2,
};
const defaultPassword = 'Nomadic1234@';
const eventType = {
  registerClinic: 'REGISTER_CLINIC',
  registerPatient: 'REGISTER_PATIENT',
  registerMember: 'REGISTER_MEMBER',
  addCondition: 'ADD_CONDITION',
  addTask: 'ADD_TASK',
  addAdminAuthQR: 'ADD_ADMIN_AUTH_QR',
  editClinic: 'EDIT_CLINIC',
  editUser: 'EDIT_USER',
  changeAdmin: 'CHANGE_ADMIN',
  editPatient: 'EDIT_PATIENT',
  addDocument: 'ADD_DOCUMENT',
  deleteMember: 'DELETE_MEMBER',
  removeContributor: 'REMOVE_CONTRIBUTOR',
  changeSubscription: 'CHANGE_SUBSCRIPTION_PLAN',
  updateTeamMemberInformation: 'UPDATE_TEAM_MEMBER_INFORMATION',
  addHealthNotes: 'ADD_HEALTH_NOTES',
  editHealthNotes: 'EDIT_HEALTH_NOTES',
  updateDocumentStatus: 'UPDATE_DOCUMENT_STATUS',
  completeCondition: 'COMPLETE_CONDITION',
  updateCondition: 'UPDATE_CONDITION',
  changeTaskStatus: 'CHNAGE_TASK_STATUS',
  addClinicToPatient: 'ADD_CLINIC_TO_PATIENT',
  editTask: 'EDIT_TASK',
  changeCoordinator: 'CHNAGE_CORDINATOR',
  addTeamMember: 'ADD_TEAM_MEMBER',
  addRecurringTaskEvent: 'ADD_RECURRING_EVENT',
};
const DATE_PICKER_DATE_FORMAT = 'MM-dd-yyyy';
const DATE_PICKER_DATE_FORMAT_NEW = 'dd-MM-yyyy';
const DATE_FORMAT = 'MM-DD-YYYY';
const DATE_FORMAT_NEW = 'DD-MM-YYYY';
const DATE_PICKER_TIME_FORMAT = 'HH:mm';
const DURATION_TIME_FORMAT = 'Do MMM YYYY';
const DATE_FORMAT_VIEW = 'Do MMM YYYY';

const sex = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
];
const indigenousStatus = [
  { value: 'nil', label: 'Not Applicable' },
  { value: 'toresStraightIslander', label: 'Torres Strait Islander' },
  { value: 'aboriginal', label: 'Aboriginal' },
];

const indentificationType = [
  {
    value: 'medicareNumber',
    label: 'Medicare Number',
  },
  {
    value: 'driverLicenseNo',
    label: 'Driver License No',
  },
  {
    value: 'passport',
    label: 'Passport',
  },
];

const idChar = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789';
const codeChar = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
const yearDropdownItemNumber = 10;
const modalEffect = 'fadeInDown';

const status = {
  active: {
    name: 'active',
    displayName: 'Active',
  },
  inactive: {
    name: 'inactive',
    displayName: 'Inactive',
  },
};

const documentType = {
  education: 'education',
};

const taskState = {
  accepted: {
    name: 'accepted',
    displayName: 'Accepted',
  },
  declined: {
    name: 'declined',
    displayName: 'Declined',
  },
  pending: {
    name: 'pending',
    displayName: 'Pending',
  },
};

const appointmentType = {
  teleHealth: {
    name: 'teleHealth',
    displayName: 'Telehealth',
  },
  inClinic: {
    name: 'inClinic',
    displayName: 'In Clinic',
  },
};

const timeFrame = [
  { value: 'before', label: 'Before' },
  { value: 'on', label: 'On' },
  { value: 'after', label: 'After' },
];

const taskTypes = {
  readDocument: 'readDocument',
  treatment: 'treatment',
  medication: 'medication',
  trackProgress: 'trackProgress',
  takeTest: 'takeTest',
  referral: 'referral',
  followUp: 'followUp',
};

// let firebaseFunctionsUrl =
//   'https://australia-southeast1-nc-events-uat2.cloudfunctions.net/' //UAT

// let firebaseFunctionsUrl =
//   'http://127.0.0.1:5002/nc-events-uatlf-2024-10-e135d/australia-southeast1/'

let firebaseFunctionsUrl =
  'https://australia-southeast1-nc-events-uatlf-2024-10-e135d.cloudfunctions.net/' //UATLF

console.log(process.env.REACT_APP_HOST_ENV);
if (process.env.REACT_APP_HOST_ENV === 'production') {
  firebaseFunctionsUrl =
    'https://australia-southeast1-nc-events-prod3.cloudfunctions.net/'; //Production
}

const followUpList = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
const followUpTimeFrameList = [
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' },
];
const meetingTypeList = [
  {
    value: 'inClinic',
    label: 'In Clinic',
  },
  {
    value: 'teleHealth',
    label: 'Telehealth',
    disabled: true
  },
];

const periodList = [
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' },
  { value: 'year', label: 'Year' },
];

const frequencyPeriodList = [
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' },
  { value: 'halfYear', label: '6 Months' },
];

const trackingTypes = {
  bodyWeight: 'bodyWeight',
  bmi: 'bmi',
  painLevel: 'painLevel',
  stepCount: 'stepCount',
  bloodPressure: 'bloodPressure',
  bloodGlucose: 'bloodGlucose',
  caloricIntake: 'caloricIntake',
  temperature: 'temperature',
  sleep: 'sleep',
};

const trackingTypeList = [
  { value: 'bodyWeight', label: 'Body Weight' },
  { value: 'bmi', label: 'BMI' },
  { value: 'painLevel', label: 'Pain Levels' },
  { value: 'stepCount', label: 'Step Count' },
  { value: 'bloodPressure', label: 'Blood Pressure' },
  { value: 'bloodGlucose', label: 'Blood Glucose' },
  { value: 'caloricIntake', label: 'Calorie Intake' },
  { value: 'temperature', label: 'Temperature' },
  { value: 'sleep', label: 'Sleep' },
];

const measurements = {
  bodyWeight: [
    { value: 'kg', label: 'KG' },
    { value: 'lb', label: 'LB' },
  ],
  bmi: [
    { value: 'lbs/inches2', label: 'lbs/inches2' },
    { value: 'kg/m2', label: 'kg/m2' },
  ],
  painLevel: [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
  ],
  stepCount: [{ value: 'steps', label: 'Steps' }],
  bloodPressure: [{ value: 'mmHg', label: 'mmHg (millimetres of mercury)' }],
  bloodGlucose: [{ value: 'mmol/L', label: 'mmol/L (millimoles per litre)' }],
  caloricIntake: [
    { value: 'calories', label: 'Calories' },
    { value: 'kJ', label: 'kJ (kilojoules)' },
  ],
  temperature: [
    { value: 'celcius', label: 'Celcius' },
    { value: 'fahrenheit', label: 'Fahrenheit' },
  ],
  sleep: [
    { value: 'hours', label: 'Hours of sleep' },
    { value: 'rem', label: 'REM (rapid eye movement)' },
    { value: 'nrem', label: 'NREM (non rapid eye movement)' },
  ],
};

const alertForList = [
  { value: 'lessThan', label: 'Less Than' },
  { value: 'moreThan', label: 'More Than' },
  { value: 'average', label: 'Average (Mean)' },
];

const conditionExist = 'This Condition already exists for this patient!';

const teamMemberType = {
  coordinator: {
    name: 'coordinator',
    displayName: 'Coordinator',
  },
  contributor: {
    name: 'contributor',
    displayName: 'Contributor',
  },
};

const teamStatus = {
  activated: {
    name: 'activated',
    displayName: 'Activate',
  },
  deactivated: {
    name: 'deactivated',
    displayName: 'Deactivate',
  },
  pending: {
    name: 'pending',
    displayName: 'Pending',
  },
};

const alertCount = 3;

const subscriptionPlan = {
  free: {
    name: 'free',
    displayName: 'Free',
    patientCount: 3,
    clinicianCount: 1,
  },
  basic: {
    name: 'basic',
    displayName: 'Basic',
    patientCount: 'unlimited',
    clinicianCount: 7,
  },
  premium: {
    name: 'premium',
    displayName: 'Premium',
    patientCount: 'unlimited',
    clinicianCount: 20,
  },
};

const localUrl = `${window.location.origin}/`;
// const serverUrl = "https://nomadic-care-26ff8.web.app/";

const ALGOLIA_APPID = '1BOVJO2NAI';
const ALGOLIA_ADMIN_KEY = 'c8ff4576c0605a3c8f3ec8cb8bcde0a9';
const ALGOLIA_SEARCH_KEY = 'f974dad7fe7ec148bc08ce302b1d88a6';

const uploadedByType = {
  patients: 'patients',
  users: 'users',
};

const mediaType = {
  image: 'image',
  video: 'video',
};

const shortDateFormat = 'DD MMM YYYYY';

const clinicianType = {
  externalDoctor: 'externalDoctor',
  internalDoctor: 'internalDoctor',
};

const medicationRoutesList = [
  {
    value: 'oral',
    label: 'Oral',
  },
  {
    value: 'nasalMucosa',
    label: 'Nasal mucosa (nose)',
  },
  {
    value: 'oralMucosa',
    label: 'Oral mucosa (mouth)',
  },
  {
    value: 'lip',
    label: 'Lip',
  },
  {
    value: 'ear',
    label: 'Ear',
  },
  {
    value: 'vaginal',
    label: 'Vaginal',
  },
  {
    value: 'rectal',
    label: 'Rectal',
  },
  {
    value: 'topical',
    label: 'Topical',
  },
  {
    value: 'intravenousInjection',
    label: 'Intravenous injection',
  },
  {
    value: 'intramuscularInjection',
    label: 'Intramuscular injection',
  },
  {
    value: 'subcutaneousInjection',
    label: 'Subcutaneous injection',
  },
];

const medicationUnitList = [
  {
    value: 'mg',
    label: 'Mg',
  },
  {
    value: 'g',
    label: 'G',
  },
  {
    value: 'tabletsCapsules',
    label: 'Tablets / Capsules',
    single: 'Tablet / Capsule',
  },
  {
    value: 'microGrams',
    label: 'Micro Grams',
    single: 'Micro Gram',
  },
];

const timeUnit = [
  { value: 'days', label: 'Day' },
  { value: 'weeks', label: 'Week' },
  { value: 'months', label: 'Month' },
];
export default {
  eventType,
  defaultPassword,
  DATE_PICKER_DATE_FORMAT,
  DATE_PICKER_TIME_FORMAT,
  DATE_FORMAT,
  sex,
  indigenousStatus,
  roles,
  idChar,
  codeChar,
  yearDropdownItemNumber,
  modalEffect,
  status,
  appointmentType,
  timeFrame,
  taskTypes,
  firebaseFunctionsUrl,
  // firebaseFunctionsLocalUrl,
  followUpList,
  meetingTypeList,
  periodList,
  trackingTypeList,
  measurements,
  alertForList,
  taskState,
  conditionExist,
  DURATION_TIME_FORMAT,
  teamMemberType,
  teamStatus,
  alertCount,
  trackingTypes,
  subscriptionPlan,
  localUrl,
  // serverUrl,
  ALGOLIA_APPID,
  ALGOLIA_ADMIN_KEY,
  ALGOLIA_SEARCH_KEY,
  uploadedByType,
  mediaType,
  shortDateFormat,
  DATE_FORMAT_VIEW,
  clinicianType,
  documentType,
  medicationRoutesList,
  medicationUnitList,
  DATE_FORMAT_NEW,
  DATE_PICKER_DATE_FORMAT_NEW,
  indentificationType,
  frequencyPeriodList,
  followUpTimeFrameList,
  timeUnit
};
