import React, { Component } from "react";
import animationData from "./9811-loading.json";

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStopped: false,
      isPaused: false,
    };
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="common-loader controlled">
        <img src="/assets/img/loader.gif" />
      </div>
    );
  }
}

export default Loader;
