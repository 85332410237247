import axios from "./index";

export const fetchAuthCredentials = async (email, domain = "Nomadic care") => {
  const response = await axios.post("/loginWithGoogleAuth", { email, domain });
  return response;
};

export const getVerifyGoogleCredentials = async (otp, formattedKey) => {
  const response = await axios.post("/verifyGoogleAuthKey", {
    otp,
    formattedKey,
  });
  return response;
};

export const documentTitleFetch = async (url) => {
  const response = await axios.get(
    `https://australia-southeast1-nc-events-uat2.cloudfunctions.net//fetchTitleApi?url=${url}`
  );

  return response;
};
