import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { firestoreEventsDb } from "./firebase";
import "firebase/firestore";
import { createFirestoreInstance } from "redux-firestore";

import store from "./store";

//Include Style
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/css/responsive.css";
import "./styles/css/style.css";
import Loader from "./components/Loader";

const userData = localStorage.getItem("user");

const rrfProps = {
  firebase: firestoreEventsDb,
  config: {},
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Suspense
        fallback={
          <div className="custm-loader">
            <Loader />
          </div>
        }
      >
        <App adminData={userData} />
      </Suspense>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
