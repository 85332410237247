import {
  firebaseOperationsApp,
  firestoreEventsDb,
  firestoreMasterDb,
  firestoreOperationsDb,
  operationsDb,
} from '../../firebase';
import appConstants from '../../appConstants';
import firebase from 'firebase/app';
import moment from 'moment';

export async function registerClinicEvent(metaData) {
  const result = await firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.registerClinic,
    metaData: metaData,
  });
  console.log('🚀 ~ result ~ result:', result);
  return result;
}

export async function registerUpdateTeamMemberEvent(metaData) {
  return firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.updateTeamMemberInformation,
    metaData: metaData,
  });
}

export async function getPlaceDetails(placeId) {
  return fetch(
    `${appConstants.firebaseFunctionsUrl}getPlaceDetails?placeId=${placeId}`
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result?.data?.body?.result;
      },
      (error) => {
        console.log('Error:', error);
      }
    );
}

export async function searchDrFromHealthengine(data) {
  let url = `${appConstants.firebaseFunctionsUrl}searchDrFromHealthengine`;
  // let url = `https://api.nomadiccare.com.au/doctor/search`;
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log('Error:', error);
        return error;
      }
    );
}

export async function getRoomToken(data) {
  let url = `${appConstants.firebaseFunctionsUrl}getVideoRoomToken`;
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

export async function getFieldOfMedicine() {
  return fetch(`${appConstants.firebaseFunctionsUrl}getFieldOfMedicine`)
    .then((res) => res.json())
    .then(
      (result) => {
        return result.data;
      },
      (error) => {
        console.log('Error:', error);
      }
    );
}

export async function complateBodyWeightTask(data) {
  return fetch(
    `${appConstants.firebaseFunctionsUrl}completeBodyWeightTaskWeb`,
    {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log('Error:', error);
        return error;
      }
    );
}

export async function registerPatientEvent(metaData) {
  let link = `${window.location.origin}/resetPassword`;
  return firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.registerPatient,
    metaData: metaData,
    link,
  });
}

export async function registerMemberEvent(metaData) {
  return firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.registerMember,
    metaData: metaData,
  });
}

export async function addConditionEvent(metaData) {
  let condition = await firestoreOperationsDb
    .collection('conditions')
    .add(metaData);

  await addTeamMember(
    condition.id, //condition id
    metaData.patientId, // patient id
    metaData.createdBy, //user id
    appConstants.teamMemberType.coordinator.name // type
  );

  await firestoreOperationsDb
    .collection(
      `activities/${metaData.patientId}/${appConstants.uploadedByType.users}`
    )
    .add({
      recordedBy: metaData.createdBy,
      recordedByRef: firestoreOperationsDb.doc(`/users/${metaData.createdBy}`),
      patientId: metaData.patientId,
      recordedAt: moment.utc().format(),
      userType: appConstants.uploadedByType.users,
      activity: `A new condition named ${metaData.conditionName} has been added to care plan.`,
    });
  await firestoreEventsDb
    .collection('events')
    .add({
      eventType: appConstants.eventType.addCondition,
      metaData: {
        ...metaData,
        eventCreatedAt: moment.utc().format(),
      },
    })
    .then(() => {
      console.log('Add Condintion added in event');
    });
  return condition;
}

export async function addTaskEvent(metaData, activityMetaData = {}) {
  metaData.dueDateTimeStamp = firebase.firestore.Timestamp.fromDate(
    metaData.dueDateTimeStamp
  );
  let task = await firestoreOperationsDb.collection('tasks').add(metaData);
  console.log('🚀 ~ addTaskEvent ~ task:', task);
  await addTeamMember(
    metaData.conditionId, //condition id
    metaData.patientId, // patient id
    metaData.createdBy, // user id
    appConstants.teamMemberType.contributor.name // type
  );
  if (
    metaData[appConstants.taskTypes[metaData.type]].clinician &&
    metaData[appConstants.taskTypes[metaData.type]].clinicianType ===
      appConstants.clinicianType.internalDoctor
  ) {
    await addTeamMember(
      metaData.conditionId, // condition id
      metaData.patientId, // patient id
      metaData[appConstants.taskTypes[metaData.type]].clinician, // user id
      appConstants.teamMemberType.contributor.name // type
    );
  }
  await firestoreOperationsDb
    .collection(
      `activities/${metaData.patientId}/${appConstants.uploadedByType.users}`
    )
    .add({
      recordedBy: metaData.createdBy,
      recordedByRef: firestoreOperationsDb.doc(`/users/${metaData.createdBy}`),
      patientId: metaData.patientId,
      recordedAt: moment.utc().format(),
      userType: appConstants.uploadedByType.users,
      activity: activityMetaData.activityTask || '',
    });
  await firestoreEventsDb
    .collection('events')
    .add({
      eventType: appConstants.eventType.addTask,
      metaData: {
        ...metaData,
        eventCreatedAt: moment.utc().format(),
      },
    })
    .then(() => {
      console.log('Event Added');
    })
    .catch((error) => {
      console.log('Error in events');
    });
  return task;
}

export function addTemplate(data) {
  data.forEach((ele) => {
    firestoreMasterDb.collection(`template`).add(ele);
  });
}

// addTemplate([{
//   "conditionName": "Covid quarantine",
//   "isDeleted": false,
//   "tasks": [{
//       "goal": "Check in with doctor/nurse to track quarantine progress",
//       "title": "Check-up Day 2",
//       "type": "followUp"
//   }, {
//       "goal": "Check in with doctor/nurse to track quarantine progress",
//       "title": "Check-up Day 4",
//       "type": "followUp"
//   }, {
//       "goal": "Check in with doctor/nurse to track quarantine progress",
//       "title": "Check-up Day 6",
//       "type": "followUp"
//   }, {
//       "goal": "Check in with doctor/nurse to track quarantine progress",
//       "title": "Check-up Day 8",
//       "type": "followUp"
//   }, {
//       "goal": "Check in with doctor/nurse to track quarantine progress",
//       "title": "Check-up Day 10",
//       "type": "followUp"
//   }, {
//       "goal": "Check in with doctor/nurse to track quarantine progress",
//       "title": "Check-up Day 12",
//       "type": "followUp"
//   }, {
//       "goal": "Check in with doctor/nurse to track quarantine progress, and complete 2 week quarantine",
//       "title": "Check-up Day 14",
//       "type": "followUp"
//   }]
// }])

// addTemplate([
//   {
//     "conditionName": "Diabetes – Type 2",
//     "isDeleted": false,
//     "tasks": [
//       {
//         "goal": "To improve their day-today blood sugar reading",
//         "title": "Health measurement – Blood Glucose",
//         "type": "trackProgress",
//         "trackProgress": {
//           "trackingType": "bloodGlucose",
//           "frequencyCount": "1",
//           "frequencyPeriod": "day"
//         }
//       },
//       {
//         "type": "trackProgress",
//         "goal": "Improve nutrition, reduce calories, low GI foods etc. that will help manage diabetes",
//         "title": "Track food eaten throughout the day",
//         "trackProgress": {
//           "trackingType": "caloricIntake",
//           "measurement": "",
//           "frequencyCount": "3",
//           "frequencyPeriod": "day",
//           "durationCount": "1",
//           "durationPeriod": "month"
//         }
//       },
//       {
//         "type": "trackProgress",
//         "goal": "Increase activity levels to reduce blood sugar and burn calories",
//         "title": "Walk daily 10,000 steps",
//         "goalInNumeric": 10000,
//         "trackProgress": {
//           "trackingType": "stepCount",
//           "frequencyCount": "1",
//           "frequencyPeriod": "day",
//           "durationCount": "1",
//           "durationPeriod": "month"
//         }
//       }, {
//         "type": "takeTest",
//         "goal": "Confirm blood glucose levels with blood test",
//         "title": "Blood tests (HBA1c) every 6 months (for 2 years) as directed by GP",
//         "takeTest": {
//             "typeOfTest": "Blood tests (HBA1c)"
//         }
//       }, {
//         "type": "referral",
//         "goal": "Improve blood sugar levels and what medication to take.",
//         "title": "To see a diabetes educator to further understand the condition."
//       }, {
//         "type": "referral",
//         "goal": "Increase range of exercise, introduce weight training to increase muscle mass and increase metabolism to help calorie intake",
//         "title": "Work with a personal trainer to exercise at the gym"
//       }
//     ]
//   }, {
//     "conditionName": "Mental Health",
//     "isDeleted": false,
//     "tasks": [{
//         "goal": "Improve the patients mental state and reduce symptoms of depression",
//         "title": "Assessment of mental state (MSE, ATAPS CareInMind), MHTP), and discuss care plan.",
//         "type": "followUp"
//     }, {
//         "goal": "Reduce depression and mental well-being",
//         "title": "Prescribe anti-depressant",
//         "type": "medication"
//     }, {
//         "type": "referral",
//         "goal": `1. Information transfer (as when clients/patients and their families and carers learn about symptoms, causes, and treatment concepts).
//         2. Emotional discharge (a goal served as the patient/client or family ventilates frustrations during the sessions, or exchanges with similar others their experiences of the problem).
//         3. Support of a medication or other treatment, as cooperation grows between professional and client/patient and adherence and compliance issues diminish.
//         4. Assistance toward self-help (that is, training in aspects such as prompt recognition of crisis situations and knowledge of steps to be taken).

//         Australian Institute of Professional Counsellors, 2014, Psychoeducation: Definition, Goals and Methods, www.aipc.net. au/articles/psychoeducation-definition-goals-and-methods/`,
//         "title": `Provision of psycho-education – see page 4 for more information

//         Psycho-education occurs in a range of contexts and may be conducted by a variety of professionals, each with a different emphasis. In general, however, four broad goals direct most psycho-education efforts:`,
//         "referral": {
//             "bookFollowUp": true
//         }
//     }, {
//         "goal": "Track progress through informal discussions and formal assessments conducted by the GP (such as depression tests, etc.)",
//         "title": "Stay in touch with GP",
//         "type": "followUp"
//     }]
//   }, {
//     "conditionName": "Breast Cancer",
//     "isDeleted": false,
//     "tasks": [{
//         "type": "referral",
//         "goal": "Check for presence of cancer",
//         "title": "Book in for a mammography to screen for breast cancer",
//         "referral": {
//             "bookFollowUp": true
//         }
//     }]
//   }, {
//     "conditionName": "Osteoporosis",
//     "isDeleted": false,
//     "tasks": [{
//         "type": "trackProgress",
//         "goal": "Increase use of bones to increase strength (functionality)",
//         "title": "Walk for 3000-4000 steps over 20 mins",
//         "goalInNumeric": 4000,
//         "trackProgress": {
//           "trackingType": "stepCount",
//           "frequencyCount": "1",
//           "frequencyPeriod": "day",
//           "durationCount": "1",
//           "durationPeriod": "year"
//         }
//       },{
//         "type": "readDocument",
//         "goal": "Reduce risk of breaking bones",
//         "title": "Educate on avoiding falls",
//         "readDocument": {
//           "documentName": "Osteoporosis and exercise",
//           "documentUrl": "https://www.betterhealth.vic.gov.au/health/conditionsandtreatments/osteoporosis-and-exercise#bhc-content"
//         }
//       }]
//   }, {
//     "conditionName": "Physiotherapy - Back pain",
//     "isDeleted": false,
//     "tasks": [{
//         "type": "trackProgress",
//         "goal": "Loosen and strengthen back muscles",
//         "title": "Walk for 3000-4000 steps over 20 mins in first 6 months",
//         "goalInNumeric": 4000,
//         "trackProgress": {
//           "trackingType": "stepCount",
//           "frequencyCount": "1",
//           "frequencyPeriod": "day",
//           "durationCount": "1",
//           "durationPeriod": "year"
//         }
//       },{
//         "type": "treatment",
//         "goal": "Lengthen muscles to prevent future muscle spasms",
//         "title": "Stretches – Hamstrings, lower back",
//         "treatment": {
//           "treatmentName": "Stretches – Hamstrings",
//           "instructionLetter": "https://thehillsphysio.com.au/blog/5-exercises-lower-back-pain",
//           "frequencyCount": "1",
//           "frequencyPeriod": "day",
//           "durationCount": "1",
//           "durationPeriod": "year"
//         }
//       }]
//   }])
// .then(() => {
//   console.log("Template has been added sucms")
// })
// .catch((err) => {
//   console.log("Error adding template", err)
// })

export async function addHealthNotes(metaData) {
  console.log('metaData', metaData);
  // let healthNote = await firestoreOperationsDb
  //   .collection("healthNotes")
  //   .add(metaData);
  // return healthNote;
  return firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.addHealthNotes,
    metaData: {
      ...metaData,
      eventCreatedAt: moment.utc().format(),
    },
  });
}

export async function editHealthNotes(noteId, metaData) {
  console.log('metaData', metaData);
  return firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.editHealthNotes,
    noteId: noteId,
    metaData: {
      ...metaData,
      eventCreatedAt: moment.utc().format(),
    },
  });
  // let healthNoteRef = await firestoreOperationsDb
  //   .collection("healthNotes")
  //   .doc(noteId);
  // return healthNoteRef.update(metaData);
}
export async function updateCondition(conditionId, metaData) {
  console.log('metaData', metaData);
  let healthNoteRef = await firestoreOperationsDb
    .collection('conditions')
    .doc(conditionId);

  await firestoreEventsDb
    .collection('events')
    .add({
      eventType: appConstants.eventType.updateCondition,
      conditionId: conditionId,
      metaData: {
        ...metaData,
        eventCreatedAt: moment.utc().format(),
      },
    })
    .then(() => {
      console.log('added in event', conditionId);
    });
  return healthNoteRef.update(metaData);
}

export async function editTaskEvent(taskId, metaData) {
  console.log('metaData', metaData);
  const taskRef = firestoreOperationsDb.collection('tasks').doc(taskId);
  let oldData = taskRef.get();
  oldData = (await oldData).data();
  taskRef.update(metaData);

  await addTeamMember(
    metaData.conditionId, // condition id
    metaData.patientId, // patient id
    metaData.createdBy, // user id
    appConstants.teamMemberType.contributor.name // type
  );
  if (
    metaData[appConstants.taskTypes[metaData.type]].clinician &&
    oldData[appConstants.taskTypes[oldData.type]].clinician &&
    metaData[appConstants.taskTypes[metaData.type]].clinician !==
      oldData[appConstants.taskTypes[oldData.type]].clinician
  ) {
    const teamRef = firestoreOperationsDb.collection('careTeam');
    await teamRef
      .where('isDeleted', '==', false)
      .where('patientId', '==', metaData.patientId)
      .where(
        'userId',
        '==',
        oldData[appConstants.taskTypes[oldData.type]].clinician
      )
      .get()
      .then(async function (records) {
        if (records.size > 0) {
          await records.forEach(async function (doc) {
            let record = doc.data();
            if (record.conditions) {
              if (
                record.conditions.find(
                  (c) =>
                    c.conditionId == metaData.conditionId &&
                    c.type === appConstants.teamMemberType.contributor.name
                ) &&
                record.conditions.length === 1
              ) {
                await firestoreOperationsDb
                  .collection('careTeam')
                  .doc(doc.id)
                  .delete();
              } else if (
                record.conditions.find(
                  (c) =>
                    c.conditionId == metaData.conditionId &&
                    c.type === appConstants.teamMemberType.contributor.name
                ) &&
                record.conditions.length > 1
              ) {
                let conditionObj = record.conditions.find(
                  (c) =>
                    c.conditionId == metaData.conditionId &&
                    c.type === appConstants.teamMemberType.contributor.name
                );
                const conditionRef = firestoreOperationsDb
                  .collection('careTeam')
                  .doc(doc.id);
                conditionRef.update({
                  conditions:
                    firebase.firestore.FieldValue.arrayRemove(conditionObj),
                });
              }
            }
          });
        }
      })
      .catch(function (error) {
        console.log('error:', error);
      });
    await addTeamMember(
      metaData.conditionId, // condition id
      metaData.patientId, // patient id
      metaData[appConstants.taskTypes[metaData.type]].clinician, // user id
      appConstants.teamMemberType.contributor.name // type
    );
  }

  console.log('Add in Event DATATATATA', {
    ...metaData,
    eventCreatedAt: moment.utc().format(),
  });

  await firestoreEventsDb
    .collection('events')
    .add({
      eventType: appConstants.eventType.editTask,
      taskId: taskId,
      metaData: {
        ...metaData,
        eventCreatedAt: moment.utc().format(),
      },
    })
    .then(() => {
      console.log('Added in event 1', taskId);
    });
  return taskRef;
}

export async function addRecurringTaskEvent(metaDataList, cb) {
  const batch = firestoreOperationsDb.batch();
  await metaDataList.forEach(async (doc) => {
    if (doc.dueDateTimeStamp) {
      doc.dueDateTimeStamp = await firebase.firestore.Timestamp.fromDate(
        doc.dueDateTimeStamp
      );
    }
    await addTeamMember(
      doc.conditionId, // condition id
      doc.patientId, // patient id
      doc.createdBy, // user id
      appConstants.teamMemberType.contributor.name // type
    );
    if (doc[appConstants.taskTypes[doc.type]] != undefined) {
      if (doc[appConstants.taskTypes[doc.type]].clinician) {
        await addTeamMember(
          doc.conditionId, // condition id
          doc.patientId, // patient id
          doc[appConstants.taskTypes[doc.type]].clinician, // user id
          appConstants.teamMemberType.contributor.name // type
        );
      }
    }
  });

  await metaDataList.forEach(async (doc) => {
    let activityText = doc.activityTitle;
    delete doc.activityTitle;

    var docRef = await firestoreOperationsDb.collection('tasks').doc();
    var activityRef = firestoreOperationsDb
      .collection(
        `activities/${doc.patientId}/${appConstants.uploadedByType.users}`
      )
      .doc();

    batch.set(docRef, doc);
    batch.set(activityRef, {
      recordedBy: doc.createdBy,
      recordedByRef: firestoreOperationsDb.doc(`/users/${doc.createdBy}`),
      patientId: doc.patientId,
      recordedAt: moment.utc().format(),
      userType: appConstants.uploadedByType.users,
      activity: activityText || '',
    });
  });
  await batch
    .commit()
    .then(async () => {
      cb(null, true);
      await firestoreEventsDb.collection('events').add({
        eventType: appConstants.eventType.addRecurringTaskEvent,
        metaData: {
          metaDataList,
          eventCreatedAt: moment.utc().format(),
        },
      });
    })
    .catch(function (error) {
      console.log('error:', error);
      cb(error, null);
    });
}

async function addTeamMember(conditionId, patientId, userId, type) {
  const teamRef = await firestoreOperationsDb.collection('careTeam');
  await teamRef
    .where('isDeleted', '==', false)
    .where('patientId', '==', patientId)
    .where('userId', '==', userId)
    .get()
    .then(async function (records) {
      if (records.size > 0) {
        await records.forEach(async function (doc) {
          let record = doc.data();
          if (record.conditions) {
            if (!record.conditions.find((c) => c.conditionId == conditionId)) {
              let conditionObj = {
                conditionId,
                type,
                isVisible: true,
              };
              const conditionRef = firestoreOperationsDb
                .collection('careTeam')
                .doc(doc.id);
              console.log('conditionRef', conditionRef);

              return conditionRef.update({
                conditions:
                  firebase.firestore.FieldValue.arrayUnion(conditionObj),
              });
            }
          }
        });
      } else {
        let teamData = {
          isDeleted: false,
          isReviewedByPatient: false,
          patientId,
          userId,
          status: appConstants.teamStatus.pending.name,
          conditions: [
            {
              conditionId,
              type,
              isVisible: true,
            },
          ],
        };
        
        await firestoreOperationsDb.collection('careTeam').add(teamData);
      }
      console.log('add team member---');
      await firestoreEventsDb.collection('events').add({
        eventType: appConstants.eventType.addTeamMember,
        metaData: {
          conditionId,
          patientId,
          userId,
          type,
          eventCreatedAt: moment.utc().format(),
        },
      });
    })
    .catch(function (error) {
      console.log('error:', error);
    });
}

export async function editClinicEvent(clinicId, metaData) {
  return firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.editClinic,
    metaData: metaData,
    id: clinicId,
  });
}

export async function editUserEvent(userId, metaData) {
  console.log('metaData', metaData);
  return firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.editUser,
    metaData: metaData,
    id: userId,
  });
}

export async function changeAdmin(metaData) {
  return firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.changeAdmin,
    metaData: metaData,
  });
}

export async function changeTaskStatus(taskId, value) {
  const taskRef = firestoreOperationsDb.collection('tasks').doc(taskId);
  await firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.changeTaskStatus,
    metaData: {
      taskId,
      value,
      eventCreatedAt: moment.utc().format(),
    },
  });
  return taskRef.update({ status: appConstants.status[value].name });
}

export async function editPatientEvent(patientId, metaData) {
  return firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.editPatient,
    metaData: metaData,
    id: patientId,
  });
}

export async function addClinicToPatient(clinicId, patientId) {
  var patientRef = firestoreOperationsDb.collection('patients').doc(patientId);
  await firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.addClinicToPatient,
    metaData: {
      clinicId,
      patientId,
      eventCreatedAt: moment.utc().format(),
    },
  });
  return patientRef.update({
    clinicId: firebase.firestore.FieldValue.arrayUnion(clinicId[0]),
  });
}

export async function updatePatientEmail(patientId, email) {
  var patientRef = firestoreOperationsDb.collection('patients').doc(patientId);
  return patientRef.update({
    email,
  });
}

export async function addDocumentEvent(metaData) {
  return firestoreEventsDb
    .collection('events')
    .add({
      eventType: appConstants.eventType.addDocument,
      metaData: {
        ...metaData,
        eventCreatedAt: moment.utc().format(),
      },
      // return firestoreEventsDb.collection("events").add({
      //   eventType: appConstants.eventType.addDocument,
      //   metaData: metaData,
      // });
    })
    .then(() => {
      window.location.reload();
    })
    .catch((error) => console.log('add document  error', error));
}

export async function addAdminAuthBarcode(metaData) {
  return firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.addAdminAuthQR,
    metaData,
  });
}

export async function updateDocumentStatus(docId, value, type) {
  console.log('doc type value', {
    docId,
    value,
    type,
  });
  if (type === 'task') {
    var docRef = firestoreOperationsDb.collection('tasks').doc(docId);
    return docRef
      .update({
        isDeleted: value,
      })
      .then(() => {
        firestoreEventsDb.collection('events').add({
          eventType: appConstants.eventType.updateDocumentStatus,
          docId: docId,
          value: value,
          eventCreatedAt: moment.utc().format(),
        });
      });
  }
  var docRef = firestoreOperationsDb.collection('documents').doc(docId);

  return docRef
    .update({
      isDeleted: value,
    })
    .then(() => {
      firestoreEventsDb.collection('events').add({
        eventType: appConstants.eventType.updateDocumentStatus,
        docId: docId,
        value: value,
        eventCreatedAt: moment.utc().format(),
      });
    });
}

export async function completeCondition(conditionId, updateData) {
  return firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.completeCondition,
    conditionId: conditionId,
    metadata: {
      ...updateData,
      eventCreatedAt: moment.utc().format(),
    },
  });
  // var conditionRef = firestoreOperationsDb
  //   .collection("conditions")
  //   .doc(conditionId);
  // return conditionRef.update(updateData);
}

export async function deleteTeamMember(memberId, userId) {
  return firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.deleteMember,
    id: memberId,
    taskAssignedTo: userId,
  });
}

export async function removeContributor(
  patientId,
  userId,
  conditionId,
  assignedTo,
  cb
) {
  const taskList = [];
  const teamList = [];
  const taskRef = firestoreOperationsDb.collection('tasks');
  taskRef
    .where('patientId', '==', patientId)
    .where('conditionId', '==', conditionId)
    .where('isDeleted', '==', false)
    .where('isCompleted', '==', false)
    .get()
    .then(async (tasks) => {
      await tasks.forEach(async (doc) => {
        let task = doc.data();
        task.taskId = doc.id;
        if (
          task[task.type] &&
          task[task.type].clinician &&
          task[task.type].clinician === userId
        ) {
          taskList.push(task);
        }
      });
      return;
    })
    .catch((error) => {
      console.log('error:', error);
    });

  const teamRef = await firestoreOperationsDb.collection('careTeam');
  await teamRef
    .where('patientId', '==', patientId)
    .where('userId', '==', userId)
    .where('isDeleted', '==', false)
    .get()
    .then(async (teamData) => {
      await teamData.forEach(async (doc) => {
        let team = doc.data();
        team.teamId = doc.id;
        if (
          team.conditions &&
          team.conditions.find(
            (c) =>
              c.conditionId === conditionId &&
              c.type === appConstants.teamMemberType.contributor.name
          )
        ) {
          teamList.push({
            teamId: team.teamId,
            conditions: team.conditions,
            condition: team.conditions.find(
              (c) => c.conditionId === conditionId
            ),
          });
        }
      });
      return;
    })
    .catch((error) => {
      console.log('error:', error);
    });

  const batch = firestoreOperationsDb.batch();
  await taskList.forEach(async (doc) => {
    var docRef = await firestoreOperationsDb
      .collection('tasks')
      .doc(doc.taskId);
    batch.update(docRef, { 'referral.clinician': assignedTo });
  });
  await teamList.forEach(async (doc) => {
    var docRef = await firestoreOperationsDb
      .collection('careTeam')
      .doc(doc.teamId);
    if (doc.conditions.length == 1) {
      batch.delete(docRef);
    } else {
      batch.update(docRef, {
        conditions: firebase.firestore.FieldValue.arrayRemove(doc.condition),
      });
    }
  });
  return await batch
    .commit()
    .then(async () => {
      await addTeamMember(
        conditionId, // condition id
        patientId, // patient id
        assignedTo, // user id
        appConstants.teamMemberType.contributor.name // type
      );
      cb(null, true);
      await firestoreEventsDb
        .collection('events')
        .add({
          eventType: appConstants.eventType.removeContributor,
          metaData: {
            patientId,
            userId,
            conditionId,
            assignedTo,
            eventCreatedAt: moment.utc().format(),
          },
        })
        .then(() => {
          console.log('added SuccessFully Event');
        })
        .catch((error) => {
          console.log('Error even', error);
        });
    })
    .catch((error) => {
      console.log('error:', error);
      cb(error.message, null);
    });
}

export async function changeSubscriptionPlan(clinicId, plan) {
  return firestoreEventsDb.collection('events').add({
    eventType: appConstants.eventType.changeSubscription,
    plan: plan,
    id: clinicId,
  });
}

export async function changeCoordinator(
  currentCoordinator,
  newCoordinator,
  conditionId,
  cb
) {
  const batch = firestoreOperationsDb.batch();

  const currentRef = await firestoreOperationsDb
    .collection('careTeam')
    .doc(currentCoordinator.teamId);
  await currentRef
    .get()
    .then(async (teamData) => {
      let currentData = teamData.data();
      let condition =
        currentData.conditions &&
        currentData.conditions.find((c) => c.conditionId === conditionId);
      let newCondition = {
        conditionId,
        isVisible: condition.isVisible,
        type: appConstants.teamMemberType.contributor.name,
      };

      const docRef = await firestoreOperationsDb
        .collection('careTeam')
        .doc(currentCoordinator.teamId);
      batch.update(docRef, {
        conditions: firebase.firestore.FieldValue.arrayRemove(condition),
      });
      batch.update(docRef, {
        conditions: firebase.firestore.FieldValue.arrayUnion(newCondition),
      });
    })
    .catch((error) => {
      console.log('error:', error);
    });

  const newRef = await firestoreOperationsDb
    .collection('careTeam')
    .doc(newCoordinator.teamId);
  await newRef
    .get()
    .then(async (teamData) => {
      let currentData = teamData.data();
      let condition =
        currentData.conditions &&
        currentData.conditions.find((c) => c.conditionId === conditionId);
      let newCondition = {
        conditionId,
        isVisible: condition.isVisible,
        type: appConstants.teamMemberType.coordinator.name,
      };

      const docRef = await firestoreOperationsDb
        .collection('careTeam')
        .doc(newCoordinator.teamId);
      batch.update(docRef, {
        conditions: firebase.firestore.FieldValue.arrayRemove(condition),
      });
      batch.update(docRef, {
        conditions: firebase.firestore.FieldValue.arrayUnion(newCondition),
      });
    })
    .catch((error) => {
      console.log('error:', error);
    });

  await batch
    .commit()
    .then(async () => {
      cb(null, true);
      await firestoreEventsDb.collection('events').add({
        eventType: appConstants.eventType.changeCoordinator,
        metaData: {
          currentCoordinator,
          newCoordinator,
          conditionId,
          eventCreatedAt: moment.utc().format(),
        },
      });
    })
    .catch((error) => {
      console.log('error:', error);
      cb(error.message, null);
    });
}

export async function createCheckoutSession(clinicDetail, selectedPlan) {
  return fetch(`${appConstants.firebaseFunctionsUrl}createCheckoutSession`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      plan: selectedPlan,
      domainUrl: appConstants.localUrl,
      clinicDetail,
      // coupon: "NOMADIC10",
    }),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        console.log('result:', result);
        return result.data.sessionId;
      },
      (error) => {
        console.log('Error:', error);
      }
    );
}

export async function updateSubscriptionPlan(sessionId, userName) {
  return fetch(
    `${appConstants.firebaseFunctionsUrl}updateSubscriptionPlan?sessionId=${sessionId}&userName=${userName}`
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result.data;
      },
      (error) => {
        console.log('Error:', error);
      }
    );
}

export async function fetchCustomerPortal(sessionId, customerId) {
  return fetch(`${appConstants.firebaseFunctionsUrl}customerPortal`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      domainUrl: `${appConstants.localUrl}clinicdetail`,
      sessionId,
      customerId,
    }),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        console.log('result:', result);
        return result.data;
      },
      (error) => {
        console.log('Error:', error);
      }
    );
}

export async function cancelSubscription(subscriptionId, clinicId) {
  return fetch(`${appConstants.firebaseFunctionsUrl}cancelSubscription`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      subscriptionId,
      clinicId,
    }),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log('Error:', error);
      }
    );
}

export async function getPaymentMethod(customerId, subscriptionId) {
  return fetch(
    `${appConstants.firebaseFunctionsUrl}paymentMethod?customerId=${customerId}&subscriptionId=${subscriptionId}`
  )
    .then((res) => res.json())
    .then(
      (result) => {
        console.log('resule:', result);
        return result.data;
      },
      (error) => {
        console.log('Error:', error);
      }
    );
}

export async function addExternalClinician(options, invitee) {
  return new Promise(async (resolve, reject) => {
    try {
      let newId = await firestoreOperationsDb
        .collection('externalDoctors')
        .add(options);

      await fetch(`${appConstants.firebaseFunctionsUrl}sendEmailAPI`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: options.clinicianEmail,
          template: 'externalDoctorInvitaion',
          emailVariables: {
            link: `${window.location.origin}/signup?token=${newId.id}`,
            name: options.clinicianName,
            invitee,
            patientId: options.patientId,
          },
        }),
      });
      resolve(newId.id);
    } catch (error) {
      reject(error.message);
    }
  });
}

export async function getExternalDrInformation(id, cb) {
  try {
    let doc = await firestoreOperationsDb
      .collection('externalDoctors')
      .doc(id)
      .get();
    cb(null, Object.assign({ id: doc.id }, doc.data()));
  } catch (error) {
    console.log('error', error);
    cb(error, null);
  }
}

export async function getTeamMemberInfomation(id, cb) {
  try {
    let doc = await firestoreOperationsDb.collection('users').doc(id).get();
    cb(null, Object.assign({ id: doc.id }, doc.data()));
  } catch (error) {
    console.log('error', error);
    cb(error, null);
  }
}


export async function checkExternalDoctorExist(value, type) {
  console.log({value, type})
  try {
    let ref = firestoreOperationsDb.collection('externalDoctors');
    let querySnapshot;

    if (type === "phone") {
      querySnapshot = await ref.where('clinicianPhone', '==', value).get();
    } else if (type === "email") {
      querySnapshot = await ref.where('clinicianEmail', '==', value).get();
    }

    if (!querySnapshot.empty) {
      // Return the first document found
      return querySnapshot.docs[0].data();
    } else {
      return null; // Return null if no document found
    }
  } catch (error) {
    console.error("Error checking external doctor existence:", error);
    throw new Error("Unable to check external doctor existence.");
  }
}


export async function removeExternalDr(externalDoctorId, clici) {
  try {
    let patinetIds = [];
    let externalDrId = [];
    let emailToFind = 'monu+7788@seraphic.io';

    let externalDrIdData = await operationsDb
      .collection('externalDoctors')
      .where('clinicianEmail', '==', emailToFind)
      .get();
    externalDrIdData.forEach((doc) => {
      patinetIds.push(doc.data().patientId);
      externalDrId.push(doc.id);
    });

    patinetIds = [...new Set(patinetIds)];
    console.log('externalDrId', patinetIds);
    let alltasks = await operationsDb
      .collection('tasks')
      .where('referral.clinician', 'in', externalDrId)
      .where('isDeleted', '==', false)
      .get();

    alltasks.forEach((doc) => {
      console.log('Doc Id', doc.id, doc.data());
    });
  } catch (error) {
    console.log('error', error);
    // cb(error, null)
  }
}

